* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'DM Sans', sans-serif;
}


html {
  height: 100%;
}

body {
  position: relative;
  height: 100%;
  background: #FAFAFA;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#root main {
  flex: 1 1 auto;
}

p, h1, h2, h3, h4, h5, h6, a {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.btn-rounded {
  border-radius: 50rem;
  cursor: pointer;
  padding: 6px 40px;
  border: 1px solid transparent;
}


.btn-calipso {
  font-weight: 700;
  height: 50px;
  background: $calipso;
  color: black;
}

.btn-dark-c {
  font-weight: 500;
  font-size: 0.875rem;
  padding: 16px 40px;
  background: #282828;
  color: white;
}


.allegory-h1 {
  font-size: 3.125rem;
  font-family: 'Zilla Slab', serif;
  font-weight: 500;
  line-height: normal;
}

.allegory-h2 {
  font-size: 2rem;
  font-family: 'Zilla Slab', serif;
  font-weight: 500;
  line-height: 1.2;
}

.allegory-container {
  //background: red;
  padding-left: 40px;
  padding-right: 40px;
}

.allegory-main-image {
  padding-top: 5.5rem;
  background-image: url(../assets/img/hero.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.main-padding-y {
  padding-top: 70px;
  padding-bottom: 70px;
}

.search-container {
  padding: 0 80px;
  align-items: center;
}

.disabled {
  pointer-events: none;
}

.disabled-bg {
  background: #4b4b52;
}


.btn-square-calipso {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $calipso;
  font-weight: 700;
  border-radius: 10px;
  width: 180px;
  height: 80px;
  margin: 15px;
  border: 2px solid $calipso;
  cursor: pointer;
  transition: 0.25s;

  &.active {
    border: 2px solid blueviolet;
  }

  &:hover {
    border: 2px solid blueviolet;
  }

}

.w700 {
  font-weight: 700;
}


.shape-img-container {
  padding: 12px;
  margin: 24px;
  border: 1px solid black;
  border-radius: 10px;
  transition: 0.25s;
  cursor: pointer;

  &:hover, &.active {
    border: 1px solid blueviolet;
  }

  img {
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
    width: 200px;
    height: 200px;
  }
}

.cp {
  cursor: pointer;
}

.footer {
  background: white;
  border-top: 1px solid #5F5F5F;
  min-height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icons {
    font-size: 1.2rem;
    font-weight: 900;

  }

  a {
    color: black;
    font-weight: 500;
    font-size: 0.90625rem;
  }

  .logo {
    width: auto;
    height: 2.1875rem;
  }

}


@include media-breakpoint-down(md) {
  .search-container {
    padding: 0 10px;
  }

  .allegory-h1 {
    font-size: 2.3rem;
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    a {
      color: black;
      font-weight: 500;
      font-size: 0.90625rem;
    }

    .logo {
      width: auto;
      height: 2.1875rem;
    }

  }
}

@include media-breakpoint-down(xl) {
  .allegory-main-image {
    padding-top: 0;
    background-image: url(../assets/img/hero.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

}



