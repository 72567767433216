
.primary-navbar {
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 15px 30px;
  align-items: center;
  min-height: 7rem;
  border-bottom: 1px solid #5F5F5F;
  background: white;

  .navbar-items {
    padding: 0 15px;
    //display: block;

    .active {
      color: $calipso;
    }

    a {
      color: black;
      font-weight: 500;
      font-size: 0.90625rem;
      padding: 0 10px;

      &:hover {
        color: $calipso;
        cursor: pointer;
      }
    }

  }

  .navbar-logo {
    transition: 0.25s;
    display: flex;
    padding: 0 15px;

    &-container {
      margin: auto;
      height: 50px;
      width: 142px;

      img {

        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
      }

    }
  }

  .navbar-end {
    display: flex;

    div {
      margin-left: auto;
    }
  }

}

.tab-selector {
  display: flex;
  justify-content: space-between;

  .item {
    position: relative;
    padding: 20px 0;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #DEE2E6;
    color: #6C757D;
    font-weight: 700;
    cursor: pointer;

    &.enabled{
      color: #7b7bff;
    }

    &.active {
      position: relative;
      color: black;
      &::before{
        position: absolute;
        height: 3px;
        background: $calipso;
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        animation: line-to-right 0.15s ease-in;
      }
    }

  }
}

@keyframes line-to-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@include media-breakpoint-down(md) {
  .primary-navbar {
    min-height: initial;

    .navbar-items {
      padding: 10px 0;
      text-align: center;
      a{
        display: block;
        padding: 5px ;
      }
    }
  }

  .tab-selector {
    flex-wrap: wrap;

    .item{
     width: 50%;
    }
  }
}

