.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 18px;

  .img {
    padding: 15px;
    background: $calipso;
    border-radius: 10px;
    cursor: pointer;
    border: 2px solid $calipso;
    transition: 0.25s;

    &:hover {
      border: 2px solid blueviolet;
    }

    &.active {
      border: 2px solid blueviolet;
    }

    &:hover {
      background: #282828;
      fill: white;
    }

    .img-container {
      width: 60px;
      height: 60px;

      img {
        object-position: center;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }


}

.product-card-result {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  max-width: 370px;

  h5, p {
    padding-bottom: 20px;
    word-break: break-word;
  }

  .img-container {
    height: 377px;
    width: 310px;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }

  }

}

.font-zs {
  font-family: 'Zilla Slab', serif;

}

.post-card {
  display: flex;
  flex-direction: column;
  width: 320px;
  margin: 30px;
  cursor: pointer;

  .post-body {
    padding: 20px;
  }

  h1 {
    font-size: 1.625rem;
    font-weight: 700;
    font-family: 'Zilla Slab', serif;
  }

  .img-container {
    height: auto;
    width: 320px;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }

  }


}












