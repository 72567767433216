$primary: #BF38FF;
$primary-alt: #EF91C3;

$background: #FAFAFA;
$white: #FFFFFF;

$gray-1: #A3A3A3;
$gray-2: #4F4F4F;
$gray-3: #6E7077;

$calipso: #8CD7D7;

$athens-gray: #F5F5F7;

$error: #F74D71;
$dark-purple: #840996;
$turquoise: #00B0FF;

$border-radius: 12px;
$box-shadow: 0px 0px 20px rgba(170, 170, 170, 0.15);

$breakpoints: (
  "xs": 0,
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
  "2xl": 1400px
)